import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import youtubeLogo from '../assets/images/youtubeLogo.png'
import instagramLogo from '../assets/images/instagramLogo.png'
import facebookLogo from '../assets/images/facebookLogo.png'
import twitterLogo from '../assets/images/twitterLogo.png'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  logo: {
    maxWidth: "1000px",
    width: "70%",
    height: "auto",
    margin: "5px",
  },
}));

const Contact = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      align="center"
      className={classes.root}
      xs={12}
    >

      <Grid item xs={12} md={6}>
        <a href="https://www.youtube.com/channel/UCw2I2MoiVMz00P-YGt8DfVg">
          <img
            className={classes.logo}
            src={
              youtubeLogo
            }
          />
        </a>
      </Grid>
      <Grid item xs={12} md={6}>
        <a href="https://www.instagram.com/wrappedreviews/">
          <img
            className={classes.logo}
            src={
              instagramLogo
            }
          />
        </a>

      </Grid>
      <Grid item xs={12} md={6}>
        <a href="https://www.facebook.com/Wrapped-Reviews-107954777507359">
          <img
            className={classes.logo}
            src={
              facebookLogo
            }
          />
        </a>

      </Grid>
      <Grid item xs={12} md={6}>
        <a href="https://twitter.com/WrappedReviews">
          <img
            className={classes.logo}
            src={
              twitterLogo
            }
          />
        </a>

      </Grid>
    </Grid>
  );

}

export default Contact;