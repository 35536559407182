import React, { Component, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  map: {
    flex: 1,
  }
}));
const position = [32.7, -117.10];
const MapComp = (props) => {
  const [markers, setMarkers] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    props.db.collection("locations").get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let data = doc.data();
        setMarkers(old =>
          [
            ...old, 
          <Marker key={doc.id} position={[data.lat, data.lng]}>
            <Popup>
          <b>{data.name}</b>
          <br></br>
          Wrapped Score: {parseFloat(data.wrappedScore).toFixed(1)}
          <br></br>
          Video Review: <a href={data.youtubeURL}> {data.youtubeURL}</a>
            </Popup>
          </Marker>
          ]
        );
      });
    });
  }, []
  );
  return (
    <div id="map" className={classes.root}>
      <Map className={classes.map} center={position} zoom={10}>
        <TileLayer
          flex="1"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        {markers}
      </Map>
    </div>
  );

}

export default MapComp;