export function assignRankings(docs) {
    docs.sort((a, b) => (a.wrappedScore < b.wrappedScore) ? 1 : -1);
    for (let i = 0; i < docs.length; i++) {
        let rank = i+1;
        
        // check ahead for ties
        if (i < docs.length - 1 && docs[i].wrappedScore == docs[i+1].wrappedScore) {
            docs[i].rank = "T" + rank;
            docs[++i].rank = "T" + rank;
            while (i < docs.length - 1 && docs[i].wrappedScore == docs[i+1].wrappedScore) {
                docs[++i].rank = "T" + rank;
            }
        } else {
            docs[i].rank = rank.toString();
        }

    }
}

export function getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
}