import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Accordion, AccordionDetails, AccordionSummary, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import * as dataHelper from '../utilities/dataHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import rulerIcon from '../assets/images/ruler.png'
import weightIcon from '../assets/images/weight.png'
import moneyIcon from '../assets/images/money.png'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1,0,0',
    justifyContent: 'center'
  },
  list: {
    justifyContent: 'center',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 0,
    fontWeight: 'bold',
    fontSize: '30px',
    fontFamily: 'Futura'
  },
  gradGrid: {
    textAlign: 'center',
  },
  gradText: {
    backgroundImage: 'linear-gradient(90deg, #e82102, #e88802)',
    backgroundColor: '#f3ec78',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: 'calc(30px + 4vw)',
    fontFamily: 'Futura',
    WebkitTextStroke: '2px white',
    padding:'3px'
  },
  gradContainer: {
    display:'inline-block',
    backgroundColor: 'black',
    marginRight: '20px',
    marginLeft: '20px',
    padding: '12px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: 'bold',
    paddingLeft: '10px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
  },
  details: {
    background: theme.palette.primary.light
  },
  grid: {
    flex: '1, 0, 0'
  },
  stats: {
    padding: '20px',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
  },
  specItem: {
    textAlign: 'center',
    fontSize: '1.2rem',
    paddingBottom: '1rem',
    fontFamily: 'Futura'
  },
  rowIcon: {
    verticalAlign: 'middle'
  },
  scoreItem: {
    fontFamily: 'Futura',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  number: {
    float: 'right',
    fontSize: '1.2rem'
  },
  videoContainer: {
    overflow: 'hidden',
    /* 16:9 aspect ratio */
    paddingTop: '56.25%',
    position: 'relative',
  },
  video: {
    border: 0,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
}));

const Rankings = (props) => {
  console.log(props)
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    props.db.collection("locations").get().then(querySnapshot => {
      let docArray = [];
      querySnapshot.forEach(doc => {
        docArray.push(doc.data());
      });
      dataHelper.assignRankings(docArray);
      setDocs(docArray)
      console.log(docs)
    });
  }, []
  );

  return (<>
    <h2 className={classes.title}>CALIFORNIA BURRITO RANKINGS</h2>
    <div className={classes.root}>
      <List className={classes.list} dense={true}>
        {docs.map((item, i) =>
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={handleChange(i)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{item.rank}</Typography>
              <Typography className={classes.secondaryHeading}>{item.name}</Typography>
            </AccordionSummary>
            <AccordionDetails
              className={classes.details}>
              <Grid
                container
                className={classes.grid}
              >
                <Grid item xs={12} md={6}
                >
                  <div
                    className={classes.videoContainer}>
                    <iframe
                      className={classes.video}
                      src={'//www.youtube.com/embed/' + dataHelper.getYoutubeId(item.youtubeURL)}
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
                    />
                  </div>
                </Grid>
                <Grid item container xs={12} md={6}
                  className={classes.stats}
                >
                  <Grid item xs={4} className={classes.specItem}
                  >
                    <img
                      className={classes.rowIcon} src={rulerIcon}>
                    </img> &nbsp;{item.length}''
                  </Grid>
                  <Grid item xs={4} className={classes.specItem}>
                    <img
                      className={classes.rowIcon} src={weightIcon}>
                    </img> &nbsp;{item.mass}g
                  </Grid>
                  <Grid item xs={4} className={classes.specItem}>
                    <img
                      className={classes.rowIcon} src={moneyIcon}>
                    </img>&nbsp;${item.price.toFixed(2)}
                  </Grid>
                  <Grid item xs={6} className={classes.gradGrid}>
                    <Card className={classes.gradContainer}
                      variant="outlined">
                    <span className={classes.gradText}>{item.wrappedScore.toFixed(1)}</span>
                    </Card>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12} className={classes.scoreItem}>
                      <span className={classes.label}>Rich's Score: </span> <span className={classes.number}>{item.richardScore.toFixed(1)}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.scoreItem}>
                      <span className={classes.label}>Chris' Score: </span> <span className={classes.number}>{item.chrisScore.toFixed(1)}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.scoreItem}>
                      <span className={classes.label}>Adrian's Score: </span> <span className={classes.number}>{item.adrianScore.toFixed(1)}</span>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </div>
  </>
  );
}

export default Rankings;