import React, { useState } from 'react';
import { withRouter, NavLink } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../assets/images/stackedLogo.png'
import { Toolbar, AppBar, Button, useMediaQuery, MenuItem, IconButton, Menu } from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: '48px',
  },
  logoContainer: {
    flexGrow: '1',
    flexBasis: 0,
  },
  logoContainerMobile: {
    flexGrow: '1,0,0',
    textAlign: 'center',
  },
  iconContainer: {
    flexGrow: 1,
    flexBasis: 0,
    textAlign: 'right',
  },
  spacer: {
    flexGrow: 1,
    flexBasis: 0,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    margin: '0 auto',
    justifyContent: 'center',
  },
  buttonStyle: {
    width: '100px',
    marginLeft: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  selectedButtonStyle: {
    width: '100px',
    marginLeft: '5px',
    marginRight: '5px',
    backgroundColor: '#ffffff',
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  menuButton: {
    flexGrow: 1,
    flexBasis: 0,
  },
  appBarSpace: theme.mixins.toolbar
}));


function NavBar(props) {
  const { history } = props;
  //const [selectedButton, setSelectedButton] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <AppBar position="absolute" className={classes.root}>
        <Toolbar>
          {isMobile ? (
            <>

              <div className={classes.spacer}></div>
              <div className={classes.logoContainerMobile}>
                <img src={logo} className={classes.logo} ></img>
              </div>
              <div className={classes.iconContainer}>
                <IconButton
                  edge="end"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {props.routes.map(menuItem => {
                  const { key, component, path, title } = menuItem;
                  return (
                    <MenuItem
                    component={NavLink}
                    to={path}
                    key={menuItem.key}
                    onClick={() => setAnchorEl(null)}
                    >
                      {title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          ) : (
              <>
                <div className={classes.logoContainer}>
                  <img src={logo} className={classes.logo} ></img>
                </div>
                <div className={classes.buttonContainer}>
                  {props.routes.map(button =>
                    <Button
                      component={NavLink}
                      to={button.path}
                      exact
                      className={classes.buttonStyle}
                      activeClassName={classes.selectedButtonStyle}
                      variant='contained'
                      color='secondary'
                      key={button.key}
                    >
                      {button.title}
                    </Button>)}
                </div>
                <div className={classes.spacer}></div>
              </>
            )}

        </Toolbar>
      </AppBar>
      
    </>
  );
}

export default withRouter(NavBar);