import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
var config = {
    apiKey: "AIzaSyDNCbAaO3QFahZLYTmWLOliFaW5qRZVIH4",
    authDomain: "wrapped-reviews.firebaseapp.com",
    databaseURL: "https://wrapped-reviews.firebaseio.com",
    projectId: "wrapped-reviews",
    storageBucket: "wrapped-reviews.appspot.com",
    messagingSenderId: "880954903234",
    appId: "1:880954903234:web:bc2fb4ee62dd72c07c095b",
    measurementId: "G-F00Q74302Z"
};

firebase.initializeApp(config);
export default firebase;