import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Contact from "./Contact";

const useStyles = makeStyles((theme) => ({
  root:{
    alignContent:"flex-start",
    fontSize: '30px',
    fontFamily: 'Futura'
  },
  container: {
    overflow: "hidden",
    /* 16:9 aspect ratio */
    paddingTop: "56.25%",
    position: "relative",
    flexBasis: 0,
    alignContent: 'flex-start'
  },
  media: {
    border: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
}));

const Home = (props) => {
  const classes = useStyles();
  return (
    <>

      <Grid
      className={classes.root}
        container
        item
        xs={12}
        direction="row"
        justify="center"
      >
        <Grid item xs={12} md={6}>
          <div className={classes.container}>
            <iframe className={classes.media}
              src="https://www.youtube.com/embed/9pdp8_p3kLE"
            >
            </iframe>
          </div>
        </Grid>
        <Grid item align="center" xs={12}>
          <h3>Where to find us:</h3>
        </Grid>
        <Contact></Contact>
      </Grid>
      

    </>
  );
}

export default Home;