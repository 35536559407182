import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffae42",
      light: "#ffae42",
    },
    secondary: {
      main: "#63D1F4",
    },
    background: {
      default: "#63D1F4",
    },
    text: {
      primary: "#000000"
    }
  },
  typography: {
    fontFamily: [
      "Open Sans"
    ],
  },
});

ReactDOM.render(
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
