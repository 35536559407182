import React, { Component } from 'react';
import NavBar from './components/NavBar';
import './App.css';
import firebase from './firebase'
import { Route, Switch } from 'react-router-dom';
import Home from "./components/Home";
import Rankings from "./components/Rankings";
import MapComp from './components/Map';
import { makeStyles } from '@material-ui/core/styles';

const analytics = firebase.analytics();
const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1, 0, 0',
    overflow: 'auto',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  appBarSpace: theme.mixins.toolbar,
}));

const routes = [
  {
    key: 0,
    component: Home,
    path: '/',
    title: 'Home',
  },
  {
    key: 1,
    component: Rankings,
    path: '/rankings',
    title: 'Rankings',
  },
  {
    key: 2,
    component: MapComp,
    path: '/map',
    title: 'Map',
  },

]

export default function App() {
  let classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <NavBar routes={routes}></NavBar>
        <main className={classes.content}>
          <div id="spacer" className={classes.appBarSpace}></div>
          <Switch>
            {routes.map((route) =>
              <Route
                key={route.key}
                exact from={route.path}
                render={props => <route.component {...props} db={db} />}></Route>)}
          </Switch>
        </main>
      </div>
    </>
  );
}

